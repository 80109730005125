import { Menu, MenuProps } from "@mui/material";
import { Fragment, FunctionalComponent, h } from "preact";
import { memo } from "preact/compat";

interface IProps {
  id: string;
  open: boolean;
  sx: { top: number };
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  onClose: () => void;
}

const HeaderMenu: FunctionalComponent<IProps> = ({
  id,
  open,
  sx,
  anchorEl,
  onClose,
  children,
}: any) => {
  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      MenuListProps={{
        sx: { px: 1.1, py: 1.5, width: 208, borderRadius: 1.25 },
      }}
      id={id}
      open={open}
      sx={sx}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      {children}
    </Menu>
  );
};

export default memo(HeaderMenu);
