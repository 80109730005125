import { getEditorHost, getEnvName } from "./configs/env";
import { DocumentItem } from "./models/document";

export const isEnterprisePlan = (plan: string) => {
  return plan === "enterprise" || plan === "evaluation";
};

export const getPercents = (current: number, max: number): number =>
  (current / max) * 100;

export const getPercentsForCircles = (current: number, max: number): number => {
  if (current >= max) {
    return 100;
  } else {
    return getPercents(current, max);
  }
};

export const numberWithCommas = (x: string | number | null): string => {
  if (!x) {
    return "0";
  }

  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const bytesToMegaBytes = (bytes: number): number => {
  const converted = bytes / (1024 * 1024);
  return parseInt(converted.toFixed(2));
};
export const bytesConverter = (
  bytes: number,
  decimals = 1
): { value: number; symbol: string } => {
  if (bytes === 0) return { value: 0, symbol: "Bytes" };

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const value = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return {
    value,
    symbol: sizes[i],
  };
};
export const bytesToSize = (bytes: number, decimals = 1): string => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
};

export const isoToDate = (isoDate: string): string => {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  let month: number | string = date.getMonth() + 1;
  let dt: number | string = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return dt + " " + month + " " + year;
};

export const isoToDateWithSeperator = (
  isoDate: string,
  seperator: string,
  yearFormat: "full" | "short" = "full"
): string => {
  const date = new Date(isoDate);
  let year;
  if (yearFormat === "full") {
    year = date.getFullYear();
  } else {
    year = date.getFullYear().toString().slice(-2);
  }

  let month: number | string = date.getMonth() + 1;
  let dt: number | string = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return dt + seperator + month + seperator + year;
};

export const fileNameFromUri = (item: DocumentItem): string => {
  console.log(item)
  let fileUri = item?.fileUri || item?.originalFileUri;
  if (item?.fileType === "text/html") {
    fileUri = item.fileUri;
  }
  const splitArr = fileUri.split("/");
  const fileName = splitArr[splitArr.length - 1];
  return fileName;
};

export const toKorraDateFormat = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleDateString("en-US", { month: "short" });
  const year = date.getFullYear().toString().slice(-2); // Last two digits of the year
  return `${day}-${month}-${year}`;
};

export const formatTimestamp = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);
  const formattedDate = toKorraDateFormat(date);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  
  return `${formattedDate} ${hours}:${minutes}`;
};

export const getAppBaseHref = () => {
  if (getEditorHost().includes("editor1")) {
    return "https://app1.korra.ai";
  }
  if (getEditorHost().includes("editor2")) {
    return "https://app2.korra.ai";
  }
  if (getEditorHost().includes("local")) {
    return "https://app-local.korra.ai:3000";
  }
  if (getEditorHost().includes("dev-azure")) {
    return "https://app-dev.korra.ai";
  }
  if (getEditorHost().includes("dev")) {
    return "https://app-dev.korra.ai";
  }
  if (getEditorHost().includes("prodz")) {
    return "https://app.korra.ai";
  }
  return `https://app${
    getEnvName() == "prod" ? "" : "-" + getEnvName()
  }.korra.ai`;
};

export const isAlphaNumeric = (str: string) => {
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      // !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      return false;
    }
  }
  return true;
};

export const isLowerCaseAlphaNumericWithDash = (str: string): boolean => {
  try {
    var code, i, len;
    for (i = 0, len = str.length; i < len; i++) {
      code = str.charCodeAt(i);
      if (
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 96 && code < 123) && // lower alpha (a-z)
        !(code === 45) // dash
      ) {
        return false;
      }
    }
    return true;
  } catch (error: any) {
    console.log(error?.message);
    return false;
  }
};

export const isLowerCaseAlphaNumericWithDashAndDot = (str: string): boolean => {
  try {
    var code, i, len;
    // if the str is in hebrew , return true
    if (str.match(/[\u0590-\u05FF]/)) {
      return true;
    }
    for (i = 0, len = str.length; i < len; i++) {
      code = str.charCodeAt(i);
      if (
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 96 && code < 123) && // lower alpha (a-z)
        !(code === 45) && // dash
        !(code === 46) // dot
      ) {
        return false;
      }
    }
    return true;
  } catch (error: any) {
    console.log(error?.message);
    return false;
  }
};

export const toggleOrder = (order: "desc" | "asc"): "desc" | "asc" => {
  if (order === "desc") {
    return "asc";
  } else if (order === "asc") {
    return "desc";
  } else {
    console.error("toggleOrder : parameters are not correct");
  }
};

export const compareDecsByDate = <T extends { creationTime: string }>() => {
  return function innerSort(a: T, b: T) {
    if (
      !a.hasOwnProperty("creationTime") ||
      !b.hasOwnProperty("creationTime")
    ) {
      // property doesn't exist on either object
      return 0;
    }

    let varA;
    let varB;

    varA = new Date(a.creationTime);
    varB = new Date(b.creationTime);

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }

    return comparison * -1;
  };
};
