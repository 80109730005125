import { FunctionalComponent, h, Fragment } from "preact";
import Header from "../header";
import Content from "../content";
import Sidebar from "../sidebar";
import styles from "./styles.module.scss";
import { useEffect, useState } from "preact/hooks";
import { useWorkspaceContext } from "../../context/WorkspaceContext";
import { Roles } from "../../models/roles";

interface IProps {
}

const Layout: FunctionalComponent<IProps> = ({children}) => {
  const { passMaxLimit, showSharepointWarning, currentUserRole } = useWorkspaceContext();

  const [headerHight, setHeaderHight] = useState<number>(68);
  const [mainContentHeight, setMainContentHeight] = useState<string>(
    `calc(100vh - ${headerHight}px)`
  );

  //const headerHight = 68
  // const mainContentHeight = `calc(100vh - ${headerHight}px)`

  useEffect(() => {
    if (passMaxLimit) {
      setHeaderHight(68 + 31);
    } else {
      setHeaderHight(68);
    }
  }, [passMaxLimit]);

  useEffect(() => {
    setMainContentHeight(`calc(100vh - ${headerHight}px)`);
  }, [headerHight]);

  return (
    <div className={styles.container}>
      <Sidebar currentUserRole={currentUserRole} />
      <div className={styles.container__content}>
        <Header height={headerHight} showLimitBar={passMaxLimit} showSharepointWarning={showSharepointWarning} />
        {currentUserRole === Roles.RoleAgent ? null : (
          <Content height={mainContentHeight}>{children}</Content>
        )}
      </div>
      {/*<PrimaryModal />*/}
    </div>
  );
};

export default Layout;
