import { Fragment, FunctionalComponent, h } from "preact";
import { WorkspaceOnlyResponse } from "../../models/user-workspace";
import WorkspaceItem from "./WorkspaceItem";
import { Menu, MenuList, Paper, Popper } from "@mui/material";
import { MutableRef } from "preact/hooks";

interface IProps {
  items: WorkspaceOnlyResponse[];
  userId: string;
  token: string;
  open: boolean;
  anchorEl: HTMLElement;
  handleClose: () => void;
  handleSelectNewWorkspace: (workspaceId: string) => void;
}

const WorkspacesList: FunctionalComponent<IProps> = ({
  items,
  userId,
  token,
  open,
  anchorEl,
  handleClose,
  handleSelectNewWorkspace,
}) => {
  return (
    <Fragment>
      {items?.length > 0 ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {items.map((item) => {
            return (
              <WorkspaceItem
                item={item}
                userId={userId}
                token={token}
                handleSelectNewWorkspace={handleSelectNewWorkspace}
              />
            );
          })}
        </Menu>
      ) : null}
    </Fragment>
  );
};

export default WorkspacesList;
