import { getAuthHost } from "../configs/env";

const authHost = getAuthHost();

//makr request t ohttps://auth-dev.korra.ai/editor-sso?editorDomain=korra.ai
export async function fetchSSOConfig({
  editorDomain
}: {
  editorDomain: string;
}) {
  const url = `${authHost}/editor-sso?editorDomain=${editorDomain}`;
  const response = await fetch(url);
  return response.json().then(data => ({
    clientId: data.clientId,
    audience: data.audience,
    domain: data.domain
  }))
}
