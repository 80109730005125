import { Fragment, FunctionalComponent, h } from "preact";
import { Link, route, useRouter } from "preact-router";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { useWorkspaceContext } from "../../context/WorkspaceContext";
import MenuSettingsIcon from "../../assets/svg/MenuSettingsIcon";
import MenuAnalyticsIcon from "../../assets/svg/MenuAnalyticsIcon";
import MenuExperienceCreatorIcon from "../../assets/svg/MenuExperienceCreatorIcon";
import MenuKnowledgeManagerIcon from "../../assets/svg/MenuKnowledgeManagerIcon";
import MenuLogoIcon from "../../assets/svg/MenuLogoIcon";
import MenuAiTrainingIcon from "../../assets/svg/MenuAiTrainingIcon";
import PersonasIcon from "../../assets/svg/PersonasIcon";

import style from "./style.scss";
import CompSvg from "../../assets/svg/Comp";

import { getAppBaseHref } from "../../utils";
import { Roles } from "../../models/roles";

interface IProps {
  currentUserRole: Roles;
}

const Sidebar: FunctionalComponent<IProps> = ({ currentUserRole }) => {
  const routerContext = useRouter();
  // const {workspaceId} = useWorkspaceContext();
  const { totalBytes, workspaceId } = useWorkspaceContext();

  const navigateToWorkspacePage = () => {
    route(`/workspace/${workspaceId}`);
  };

  const navigateToPortalsPage = () => {
    route(`/workspace/${workspaceId}/portals`);
  };

  const navigateToAnalyticsPage = () => {
    route(`/stats/workspace/${workspaceId}`);
  };

  const navigateToSettingsPage = () => {
    route(`/workspace/${workspaceId}/settings`);
  };

  const navigateToAiTriningPage = () => {
    route(`/workspace/${workspaceId}/ai-training`);
  };
  const navigateToPersonasPage = () => {
    route(`/workspace/${workspaceId}/personas`);
  };
  return (
    <Fragment>
      <div className={style.sidebar}>
        {/* <div className={style.close_container}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.0001 17.3077L8.69238 12L14.0001 6.69225L14.7079 7.4L10.1079 12L14.7079 16.6L14.0001 17.3077Z"
              fill="#344054"
            />
          </svg>
        </div> */}
        <div className={style.logoWrapper}>
          <MenuLogoIcon />
        </div>
        {currentUserRole === Roles.RoleAgent ? null : (
          <List component="nav" className={style.navWrapper}>
            <Link onClick={navigateToPortalsPage}>
              <ListItemButton
                selected={
                  routerContext[0].path === "/workspace/:workspaceId/portals" ||
                  routerContext[0].path ===
                    "/workspace/:workspaceId/portals/:portalId"
                }
                sx={{
                  backgroundColor:
                    routerContext[0].path ===
                      "/workspace/:workspaceId/portals" ||
                    routerContext[0].path ===
                      "/workspace/:workspaceId/portals/:portalId"
                      ? "#063F6E !important"
                      : "inherit",
                  ":hover": { backgroundColor: "#063F6E77" },
                  height: 68,
                  py: 0,
                  pl: "18px !important",
                  width: "100%",
                }}
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <MenuExperienceCreatorIcon
                    sx={{ color: "#fff", width: 23 }}
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    color: "#fff",
                    fontSize: 16,
                    fontFamily: "Manrope",
                    fontWeight: 400,
                  }}
                  primary={"Experience Creator"}
                />
              </ListItemButton>
            </Link>
            <Link onClick={navigateToWorkspacePage}>
              <ListItemButton
                selected={
                  routerContext[0].path === "/workspace/:workspaceId" ||
                  routerContext[0].path ===
                    "/workspace/:workspaceId/kapp/:kappId"
                }
                sx={{
                  backgroundColor:
                    routerContext[0].path === "/workspace/:workspaceId" ||
                    routerContext[0].path ===
                      "/workspace/:workspaceId/kapp/:kappId"
                      ? "#063F6E !important"
                      : "inherit",
                  ":hover": { backgroundColor: "#063F6E77" },
                  height: 68,
                  py: 0,
                  pl: "18px !important",
                  width: "100%",
                }}
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <MenuKnowledgeManagerIcon sx={{ color: "#fff", width: 23 }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    color: "#fff",
                    fontSize: 16,
                    fontFamily: "Manrope",
                    fontWeight: 400,
                  }}
                  primary={"Knowledge Manager"}
                />
              </ListItemButton>
            </Link>
            {currentUserRole === Roles.RoleContentManager ? null : (
              <Link onClick={navigateToPersonasPage}>
                <ListItemButton
                  selected={
                    routerContext[0].path === "/workspace/:workspaceId/personas"
                  }
                  sx={{
                    backgroundColor:
                      routerContext[0].path ===
                      "/workspace/:workspaceId/personas"
                        ? "#063F6E !important"
                        : "inherit",
                    ":hover": { backgroundColor: "#063F6E77" },
                    height: 68,
                    py: 0,
                    pl: "18px !important",
                    width: "100%",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <PersonasIcon />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      color: "#fff",
                      fontSize: 16,
                      fontFamily: "Manrope",
                      fontWeight: 400,
                    }}
                    primary={
                      <h1>
                        Chatbot Personas
                        <small style={{ fontSize: "12px", marginLeft: "2px" }}>
                          (beta)
                        </small>{" "}
                      </h1>
                    }
                  />
                </ListItemButton>
              </Link>
            )}
            <Link onClick={navigateToAnalyticsPage}>
              <ListItemButton
                selected={
                  routerContext[0].path === "/stats/workspace/:workspaceId"
                }
                sx={{
                  backgroundColor:
                    routerContext[0].path === "/stats/workspace/:workspaceId"
                      ? "#063F6E !important"
                      : "inherit",
                  ":hover": { backgroundColor: "#063F6E77" },
                  height: 68,
                  py: 0,
                  pl: "18px !important",
                  width: "100%",
                }}
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <MenuAnalyticsIcon sx={{ color: "#fff", width: 23 }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    color: "#fff",
                    fontSize: 16,
                    fontFamily: "Manrope",
                    fontWeight: 400,
                  }}
                  primary={"Analytics"}
                />
              </ListItemButton>
            </Link>
            {currentUserRole === Roles.RoleContentManager ? null : ( // remove "|| true" when AI training is ready
              <Link onClick={navigateToAiTriningPage}>
                <ListItemButton
                  selected={
                    routerContext[0].path ===
                    "/workspace/:workspaceId/ai-training"
                  }
                  sx={{
                    backgroundColor:
                      routerContext[0].path ===
                      "/workspace/:workspaceId/ai-training /"
                        ? "#063F6E !important"
                        : "inherit",
                    ":hover": { backgroundColor: "#063F6E77" },
                    height: 68,
                    py: 0,
                    pl: "18px !important",
                    width: "100%",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <MenuAiTrainingIcon sx={{ color: "#fff", width: 23 }} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      color: "#fff",
                      fontSize: 16,
                      fontFamily: "Manrope",
                      fontWeight: 400,
                    }}
                    primary={<h1>AI Training </h1>}
                  />
                </ListItemButton>
              </Link>
            )}
            {currentUserRole === Roles.RoleContentManager ? null : (
              <Link onClick={navigateToSettingsPage}>
                <ListItemButton
                  selected={
                    routerContext[0].path === "/workspace/:workspaceId/settings"
                  }
                  sx={{
                    backgroundColor:
                      routerContext[0].path ===
                      "/workspace/:workspaceId/settings/"
                        ? "#063F6E !important"
                        : "inherit",
                    ":hover": { backgroundColor: "#063F6E77" },
                    height: 68,
                    py: 0,
                    pl: "18px !important",
                    width: "100%",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <MenuSettingsIcon sx={{ color: "#fff", width: 23 }} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      color: "#fff",
                      fontSize: 16,
                      fontFamily: "Manrope",
                      fontWeight: 400,
                    }}
                    primary={"Settings"}
                  />
                </ListItemButton>
              </Link>
            )}
          </List>
        )}
      </div>
    </Fragment>
  );
};

export default Sidebar;
