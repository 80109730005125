import { Fragment, FunctionalComponent, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { useUserAuthContext } from "../../context/UserAuthContext";
import { useWorkspaceContext } from "../../context/WorkspaceContext";
import {
  Avatar,
  LinearProgress,
  ListItemIcon,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import HeaderMenu from "./HeaderMenu";

import styles from "./styles.module.scss";
import LogoutIcon from "../../assets/svg/LogoutIcon";
import UpgradeSvg from "../../assets/svg/Upgrade";
import ContactIcon from "../../assets/svg/ContactIcon";
import Question from "../../assets/svg/Question";
import { LoadingProgressStatus } from "../../models/loading-progress-enum";
import { blue } from "@mui/material/colors";
import WorkspacesSelect from "./WorkspacesSelect";
import { Roles } from "../../models/roles";
import {PricingTable} from "../pricing-table/PricingTable";
import BillingSvg from "../../assets/svg/Billing";
import {getSharepointAuthUrl} from "../../api/sharepoint-api";

const menuItemStyle = {
  borderRadius: 5,
  padding: "8px 10px",
};

const HeaderMenuItem = styled(MenuItem)({
  ...menuItemStyle,
  ":hover": { backgroundColor: "#F1F3F7" },
});

interface IProps {
  height: number;
  showSharepointWarning?: boolean;
  showLimitBar?: boolean; //need to check what is "welcome korra" page
}
const Header: FunctionalComponent<IProps> = ({ height, showLimitBar, showSharepointWarning }) => {
  console.log({showSharepointWarning})

  const [helpAnchorEl, setHelpAnchorEl] = useState<HTMLDivElement | null>(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState<HTMLDivElement | null>(
    null
  );

  const { loadingStatus, userDetails, billingUrl, logOutFromEditor, setLoadingStatus } = useUserAuthContext();
  const { workspaceAlias, plan, workspaceId, planDisplayName, currentUserRole } = useWorkspaceContext();

  const [number, setNumber] = useState(0);

  useEffect(() => {
    if (loadingStatus === LoadingProgressStatus.INIT) {
      setNumber(number + 20);
    } else if (loadingStatus === LoadingProgressStatus.PLATFORM_LOADED) {
      setNumber(number + 20);
    } else if (loadingStatus === LoadingProgressStatus.AUTH2_LOADED) {
      setNumber(number + 20);
    } else if (loadingStatus === LoadingProgressStatus.GOOGLE_AUTH_INITILIZED) {
      //last step in login page
      setNumber(number + 20);
    } else if (loadingStatus === LoadingProgressStatus.USER_COMPANY_LOADED) {
    } else if (loadingStatus === LoadingProgressStatus.LOAD_PAGE_DATA) {
      setNumber(number + 20);
    } else if (loadingStatus === LoadingProgressStatus.DONE) {
      const num = 100 - number;
      setNumber(number < 99 || num >= 0 ? number + num : 100);
    }
  }, [loadingStatus]);

  useEffect(() => {

    //if its a user agent free the menu to load
    if(currentUserRole === Roles.RoleAgent){
      setLoadingStatus(LoadingProgressStatus.DONE)
    }

  }, [currentUserRole]);

  useEffect(() => {
    setTimeout(() => {
      if (number > 99) {
        setNumber(0);
      }
    }, 500);
  }, [window.location.pathname, number]);

  const [showPricingTable, setShowPricingTable] = useState(false);

  return (
    <Fragment>
      <PricingTable isVisible={showPricingTable} onClose={() => setShowPricingTable(false)}/>

      <header style={{ height }} >
      <div className={styles.header}>
        <div className={styles.header__company__withupgrade}>
          <h1 className={styles.header__company}>
            <WorkspacesSelect workspaceId={workspaceId} workspaceAlias={workspaceAlias} loadingStatus={loadingStatus}/>

            </h1>

        </div>

        <div className={styles.header__content}>
          <div className={styles.header__content__withupgrade}>
            <div className={styles.header__content__withupgrade__plan}>Plan: {planDisplayName}</div>
          </div>
          {(currentUserRole === Roles.RoleTeamManager || typeof currentUserRole === "undefined") && (plan === "free" || plan === "business_plus" || plan === "evaluation") && (
            <div className={styles.header__content__withupgrade__button} onClick={() => {
              if (plan === "free") {
                setShowPricingTable(true)
              } else {
                window.open("https://korra.ai/upgrade_request");
              }
            }}><UpgradeSvg />Upgrade Plan</div>
          )}

          <div
            className={styles.header__help}
            id="help-menu"
            onClick={(e) => setHelpAnchorEl(e.currentTarget)}
          >
            <div className={styles.header__help__text}>Help</div>
            <div className={styles.header__arrow} />
          </div>
          <HeaderMenu
            id="help-menu"
            open={!!helpAnchorEl}
            sx={{ top: 20 }}
            anchorEl={helpAnchorEl}
            onClose={() => setHelpAnchorEl(null)}
          >
            <HeaderMenuItem onClick={() => setHelpAnchorEl(null)}>
              <ListItemIcon sx={{ minWidth: "30px !important" }}>
                <Question />
              </ListItemIcon>
              <Typography
                fontSize={14}
                fontFamily="Manrope"
                onClick={() =>
                  window.open("https://korra.ai/home/training-center")
                }
              >
                Knowledge Center
              </Typography>
            </HeaderMenuItem>
            <div
              style={{
                height: 1,
                backgroundColor: "#E7EAEF",
                margin: "13px 0",
              }}
            />
            <HeaderMenuItem onClick={() => setHelpAnchorEl(null)}>
              <ListItemIcon sx={{ minWidth: "30px !important" }}>
                <ContactIcon />
              </ListItemIcon>
              <Typography
                fontSize={14}
                fontFamily="Manrope"
                onClick={() => window.open("https://korra.ai/contact_us")}
              >
                Contact Us
              </Typography>
            </HeaderMenuItem>
          </HeaderMenu>
          <div
            className={styles.header__profile}
            id="profile-menu"
            onClick={(e) => setProfileAnchorEl(e.currentTarget)}
          >
            <Avatar
              sx={{ bgcolor: blue[300] }}
              alt={userDetails?.userName}
              round={true}
              size={"50"}
            >
              {userDetails?.userName[0]}
            </Avatar>
          </div>
          <HeaderMenu
            id="profile-menu"
            open={!!profileAnchorEl}
            sx={{ top: 7 }}
            anchorEl={profileAnchorEl}
            onClose={() => setProfileAnchorEl(null)}
          >
            <div>
              <div className={styles.header__profile}>
                <Avatar
                  sx={{ bgcolor: blue[300] }}
                  alt={userDetails?.userName}
                  round={true}
                  size={"50"}
                >
                  {userDetails?.userName[0]}
                </Avatar>
              </div>
              <Typography
                fontWeight={700}
                fontSize={16}
                color="#20303E"
                sx={{ mt: 1.25, mb: 0.01 }}
              >
                {userDetails?.userFirstName}
              </Typography>
              <Typography fontWeight={300} fontSize={12} color="#081D34">
                {userDetails?.userEmail}
              </Typography>
            </div>

            {billingUrl && (
              <HeaderMenuItem
                sx={{
                  borderRadius: 1.25,
                  py: 1,
                  px: 1.25,
                  ":hover": { backgroundColor: "#F1F3F7" },
                }}
                onClick={() => {
                  window.open(billingUrl, "_blank");
                }}
              >
                <ListItemIcon sx={{ minWidth: "30px !important" }}>
                  <BillingSvg />
                </ListItemIcon>
                <Typography fontSize={14} fontFamily="Manrope">
                  Billing Portal
                </Typography>
              </HeaderMenuItem>
            )}
            <div
              style={{
                height: 1,
                backgroundColor: "#E7EAEF",
                margin: "13px 0",
              }}
            />
            <HeaderMenuItem
              sx={{
                borderRadius: 1.25,
                py: 1,
                px: 1.25,
                ":hover": { backgroundColor: "#F1F3F7" },
              }}
              onClick={() => {
                setProfileAnchorEl(null);
                logOutFromEditor();
              }}
            >
              <ListItemIcon sx={{ minWidth: "30px !important" }}>
                <LogoutIcon />
              </ListItemIcon>
              <Typography fontSize={14} fontFamily="Manrope">
                Log out
              </Typography>
            </HeaderMenuItem>
          </HeaderMenu>
        </div>
        {number !== 0 ? (
          <div className={styles.progressBar}>
            <LinearProgress
              variant="determinate"
              value={number}
              sx={{ backgroundColor: "#E7EAEF", height: "2px" }}
            />
          </div>
        ) : null}
        </div>
        {showLimitBar && (
          <div className={styles.limitBar}>You have reached your account quota. Please <a target="_blank" rel="noopener noreferrer" 
            onClick={() => {
              setShowPricingTable(true);
            }}
          >Upgrade</a>!</div>
        )}

        {showSharepointWarning ? (
          <div className={styles.limitBar}>Your Sharepoint session has expired, file sync has been paused. <a target="_blank" rel="noopener noreferrer" 
            onClick={async () => {
              const { url } = await getSharepointAuthUrl(workspaceId, userDetails.userId, userDetails.token);

              //window.open(url, "_blank");
              const popup = window.open(url, "popup", "width=600,height=600");

                popup.onbeforeunload = function () {
                  window.location.reload();
                }
            }}
          >Renew Sharepoint Session</a>!</div>
        ) : null}
      </header>
    </Fragment>
  );
};

export default Header;
