const BillingSvg = () => {
  return (
    <svg
      id="billing_account"
      data-name="billing account"
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
    >
      <path
        id="Path_1415"
        data-name="Path 1415"
        d="M0,0H19V19H0Z"
        fill="none"
      />
      <path
        id="Ellipse_321"
        data-name="Ellipse 321"
        d="M7.255-.5A7.755,7.755,0,1,1-.5,7.255,7.764,7.764,0,0,1,7.255-.5Zm0,14.51A6.755,6.755,0,1,0,.5,7.255,6.763,6.763,0,0,0,7.255,14.01Z"
        transform="translate(2 2.49)"
        fill="#081d34"
      />
      <path
        id="Ellipse_322"
        data-name="Ellipse 322"
        d="M2.5-.5a3,3,0,1,1-3,3A3,3,0,0,1,2.5-.5Zm0,5a2,2,0,1,0-2-2A2,2,0,0,0,2.5,4.5Z"
        transform="translate(6.755 5.698)"
        fill="#081d34"
      />
      <path
        id="Path_1416"
        data-name="Path 1416"
        d="M15.156,18.7a.5.5,0,0,1-.479-.357A2.6,2.6,0,0,0,12.2,16.5H9.119a2.566,2.566,0,0,0-2.473,1.839.5.5,0,0,1-.958-.288A3.582,3.582,0,0,1,9.119,15.5H12.2a3.582,3.582,0,0,1,3.433,2.556.5.5,0,0,1-.479.643Z"
        transform="translate(-1.407 -3.287)"
        fill="#081d34"
      />
    </svg>
  );
};

export default BillingSvg;
