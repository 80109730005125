const UpgradeSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.119"
      height="23.853"
      viewBox="0 0 19.119 23.853"
    >
      <g id="Upgrade" transform="translate(-258.187 -14.555)">
        <path
          id="Polygon_19"
          data-name="Polygon 19"
          d="M4.5,0l.743,3.758L9,4.5l-3.758.742L4.5,9,3.758,5.242,0,4.5l3.758-.742Z"
          transform="matrix(0.819, -0.574, 0.574, 0.819, 264.772, 31.036)"
          fill="#fff"
        />
        <path
          id="Polygon_19_-_Outline"
          data-name="Polygon 19 - Outline"
          d="M4.5,0l.743,3.758L9,4.5l-3.757.743L4.5,9,3.758,5.243,0,4.5l3.758-.742Z"
          transform="matrix(0.819, -0.574, 0.574, 0.819, 264.772, 31.036)"
          fill="#0093ff"
        />
        <path
          id="Polygon_20"
          data-name="Polygon 20"
          d="M6.835,0,7.962,5.634l5.707-.669L8.659,7.779,11.058,13,6.835,9.1,2.611,13l2.4-5.221L0,4.966l5.707.669Z"
          transform="translate(268.705 14.555) rotate(54)"
          fill="#fff"
        />
        <path
          id="Polygon_20_-_Outline"
          data-name="Polygon 20 - Outline"
          d="M6.835,5.1,6.506,6.735,4.846,6.54,6.3,7.359l-.7,1.519L6.835,7.744,8.064,8.878l-.7-1.519L8.823,6.54l-1.661.195L6.835,5.1m0-5.1L7.962,5.634l5.707-.669L8.659,7.779,11.058,13,6.835,9.1,2.611,13l2.4-5.221L0,4.966l5.707.669Z"
          transform="translate(268.705 14.555) rotate(54)"
          fill="#0093ff"
        />
      </g>
    </svg>
  );
};

export default UpgradeSvg;
