import { SvgIcon } from '@mui/material'
import { memo } from "preact/compat"

const MenuAnalyticsIcon = props => (
  <SvgIcon {...props}>
    <svg
      id='Menu_Analytics'
      data-name='Menu Analytics'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'>
      <path id='Path_922' data-name='Path 922' d='M0,0H24V24H0Z' fill='none' />
      <path
        id='Rectangle_2165'
        data-name='Rectangle 2165'
        d='M1.105-.5h1.79A1.607,1.607,0,0,1,4.5,1.1V10.9a1.607,1.607,0,0,1-1.605,1.6H1.105A1.607,1.607,0,0,1-.5,10.9V1.1A1.607,1.607,0,0,1,1.105-.5Zm1.79,12a.606.606,0,0,0,.605-.6V1.1A.606.606,0,0,0,2.895.5H1.105A.606.606,0,0,0,.5,1.1V10.9a.606.606,0,0,0,.605.6Z'
        transform='translate(10 9)'
        fill='#fff'
      />
      <path
        id='Rectangle_2167'
        data-name='Rectangle 2167'
        d='M1.105-.5h1.79A1.607,1.607,0,0,1,4.5,1.105v4.79A1.607,1.607,0,0,1,2.895,7.5H1.105A1.607,1.607,0,0,1-.5,5.895V1.105A1.607,1.607,0,0,1,1.105-.5Zm1.79,7A.606.606,0,0,0,3.5,5.895V1.105A.606.606,0,0,0,2.895.5H1.105A.606.606,0,0,0,.5,1.105v4.79a.606.606,0,0,0,.605.605Z'
        transform='translate(3 14)'
        fill='#fff'
      />
      <path
        id='Rectangle_2166'
        data-name='Rectangle 2166'
        d='M1.105-.5h1.79A1.607,1.607,0,0,1,4.5,1.1V16.9a1.607,1.607,0,0,1-1.605,1.6H1.105A1.607,1.607,0,0,1-.5,16.9V1.1A1.607,1.607,0,0,1,1.105-.5Zm1.79,18a.606.606,0,0,0,.605-.6V1.1A.606.606,0,0,0,2.895.5H1.105A.606.606,0,0,0,.5,1.1V16.9a.606.606,0,0,0,.605.6Z'
        transform='translate(17 3)'
        fill='#fff'
      />
    </svg>
  </SvgIcon>
)

export default memo(MenuAnalyticsIcon)
