import { SvgIcon } from '@mui/material'
import { memo } from "preact/compat"

const MenuAiTrainingIcon = props => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
      <g id="AI_icon" data-name="AI icon" transform="translate(-26 -333)">
        <circle id="Ellipse_404" data-name="Ellipse 404" cx="3" cy="3" r="3" transform="translate(26 344)" fill="#fff"/>
        <path id="Ellipse_404_-_Outline" data-name="Ellipse 404 - Outline" d="M3,1A2,2,0,1,0,5,3,2,2,0,0,0,3,1M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0Z" transform="translate(26 344)" fill="#fff"/>
        <path id="Ellipse_405" data-name="Ellipse 405" d="M3,1A2,2,0,1,0,5,3,2,2,0,0,0,3,1M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0Z" transform="translate(26 333)" fill="#fff"/>
        <path id="Ellipse_406" data-name="Ellipse 406" d="M3,1A2,2,0,1,0,5,3,2,2,0,0,0,3,1M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0Z" transform="translate(40 344)" fill="#fff"/>
        <circle id="Ellipse_407" data-name="Ellipse 407" cx="3" cy="3" r="3" transform="translate(40 333)" fill="#fff"/>
        <path id="Ellipse_407_-_Outline" data-name="Ellipse 407 - Outline" d="M3,1A2,2,0,1,0,5,3,2,2,0,0,0,3,1M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0Z" transform="translate(40 333)" fill="#fff"/>
        <path id="Path_1957" data-name="Path 1957" d="M32.361,347.786v-1c1.653,0,2.963-2.928,4.118-5.511,1.278-2.857,2.484-5.556,4.431-5.556v1c-1.3,0-2.427,2.524-3.518,4.964C36.05,344.683,34.663,347.786,32.361,347.786Z" transform="translate(-0.5)" fill="#fff"/>
      </g>
    </svg>
  </SvgIcon>
)

export default memo(MenuAiTrainingIcon)
