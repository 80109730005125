import { FunctionalComponent, h, Fragment } from "preact";
import UserAuthProvider from "../context/UserAuthContext";
import SnackBarProvider from "../context/SnackBarContext";
import { SnackbarProvider } from "notistack";
import { Auth0Provider } from "@auth0/auth0-react";

import AppRouter from "./router/router";
import {
  getAuth0Audience,
  getAuth0ClientId,
  getAuth0Domain,
  getEditorHost,
} from "../configs/env";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {useEffect, useState} from "react";
import {fetchSSOConfig} from "../api/sso-api";

function isNonSSOEditor() {
  const nonSSODomains = [
    "editor1.korra.ai",
    "editor2.korra.ai",
    "editor.korra.ai",
    "editor-dev.korra.ai",
    "editor-local.korra.ai",
    "editor-local.korra.ai:8080",
  ]

  return nonSSODomains.includes(window.location.host);
}

const App: FunctionalComponent = () => {
  const [isReady, setIsReady] = useState(isNonSSOEditor());
  const [auth0Domain, setAuth0Domain] = useState(getAuth0Domain());
  const [auth0ClientId, setAuth0ClientId] = useState(getAuth0ClientId());
  const [auth0Audience, setAuth0Audience] = useState(getAuth0Audience());

  useEffect(() => {
    if (isReady) {
      return;
    }

    fetchSSOConfig({
      editorDomain: window.location.host,
    }).then((data) => {
      setAuth0Domain(data.domain);
      setAuth0ClientId(data.clientId);
      setAuth0Audience(data.audience);
      setIsReady(true);
    }).catch(() => {
      setIsReady(true);
    })
  }, [isReady])

  return (
    <div id="app">
      <SnackbarProvider>
        <GoogleOAuthProvider clientId="864690442768-tcge6fi58ohce2kbm667cjpide1rsjeu.apps.googleusercontent.com">
          {isReady && (
            <Auth0Provider
              domain={auth0Domain}
              clientId={auth0ClientId}
              authorizationParams={{
                redirect_uri: `${getEditorHost()}/onboarding`,
                audience: `https://${auth0Audience}/api/v2/`,
              }}
            >
              <SnackBarProvider>
                <UserAuthProvider>
                  <AppRouter />
                </UserAuthProvider>
              </SnackBarProvider>
            </Auth0Provider>
          )}
        </GoogleOAuthProvider>
      </SnackbarProvider>
    </div>
  );
};

export default App;
