import { Button, ClickAwayListener, Grow, Paper, Popper } from "@mui/material";
import { Fragment, FunctionalComponent, h } from "preact";
import { memo, useEffect, useRef, useState } from "preact/compat";
import { WorkspaceOnlyResponse } from "../../models/user-workspace";
import { useUserAuthContext } from "../../context/UserAuthContext";
import { getAdminWorkspacesList } from "../../api/user-workspace-api";
import styles from "./styles.module.scss";
import { LoadingProgressStatus } from "../../models/loading-progress-enum";
import WorkspacesList from "./WorkspacesList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { route } from "preact-router";
import Spinner from "../spinner";

interface IProps {
  workspaceId: string;
  workspaceAlias: string;
  loadingStatus: LoadingProgressStatus;
}

const WorkspacesSelect: FunctionalComponent<IProps> = ({
  workspaceId,
  workspaceAlias,
  loadingStatus,
}) => {
  const { userDetails, isKorraUser } = useUserAuthContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [workspaces, setWorkspaces] = useState<WorkspaceOnlyResponse[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLButtonElement;
    setAnchorEl(target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectNewWorkspace = (workspaceId: string) => {
    route("/workspace/" + workspaceId, true);
    window.location.reload();
  };

  useEffect(() => {
    setIsLoading(true);

    if (isKorraUser && workspaceAlias) {
      setIsLoading(false);
    } else {
      if (loadingStatus === LoadingProgressStatus.DONE) {
        const userAdminWorkspace: WorkspaceOnlyResponse = {
          workspaceId: userDetails.workspaceId,
          workspaceName: userDetails.workspaceName,
        };
        //lets load the workspaces list
        if (userDetails?.workspaces?.length) {
          const workspacesIdsArr = [...userDetails.workspaces];
          const ids = workspacesIdsArr.join(",");
          getAdminWorkspaces(ids).then((workspacesList) => {
            const list = [userAdminWorkspace, ...workspacesList];
            setWorkspaces(
              list.filter((item) => item.workspaceId !== workspaceId)
            );
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
        }
      }
    }
  }, [loadingStatus, isKorraUser, workspaceAlias]);

  const getAdminWorkspaces = async (ids: string) => {
    return await getAdminWorkspacesList(
      userDetails?.workspaceId,
      ids,
      userDetails?.userId,
      userDetails?.token
    );
  };

  return (
    <Fragment>
      <div className={styles.workspacesList}>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e: MouseEvent) => handleClick(e)}
          endIcon={
            workspaces?.length ? (
              <KeyboardArrowDownIcon
                style={{
                  color: "#081D34",
                  fontSize: "15px",
                  fontFamily: "Manrope",
                  fontWeight: "700",
                }}
              />
            ) : null
          }
          className={styles.workspaceBtn}
          title={workspaceAlias || userDetails?.workspaceName}
        >
          {isLoading ? (
            <Spinner />
          ) : workspaceAlias.length > 20 ? (
            `${
              workspaceAlias.slice(0, 15) ||
              userDetails?.workspaceName.slice(0, 15)
            }...`
          ) : (
            workspaceAlias || userDetails?.workspaceName
          )}
        </Button>
        <WorkspacesList
          open={open}
          items={workspaces}
          userId={userDetails?.userId}
          token={userDetails?.token}
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleSelectNewWorkspace={handleSelectNewWorkspace}
        />
      </div>
    </Fragment>
  );
};

export default WorkspacesSelect;
