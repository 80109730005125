import { SvgIcon } from '@mui/material'
import { memo } from "preact/compat"

const MenuExperienceCreatorIcon = props => (
  <SvgIcon {...props}>
    <svg
      id='Menu_EC'
      data-name='Menu EC'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'>
      <path id='Path_922' data-name='Path 922' d='M0,0H24V24H0Z' fill='none' />
      <g id='Group_1406' data-name='Group 1406' transform='translate(-432 12819)'>
        <path
          id='Ellipse_294'
          data-name='Ellipse 294'
          d='M4,1A3,3,0,1,0,7,4,3,3,0,0,0,4,1M4,0A4,4,0,1,1,0,4,4,4,0,0,1,4,0Z'
          transform='translate(435 -12815)'
          fill='#fff'
        />
        <path
          id='Ellipse_295'
          data-name='Ellipse 295'
          d='M4,1A3,3,0,1,0,7,4,3,3,0,0,0,4,1M4,0A4,4,0,1,1,0,4,4,4,0,0,1,4,0Z'
          transform='translate(445 -12815)'
          fill='#fff'
        />
        <path
          id='Ellipse_296'
          data-name='Ellipse 296'
          d='M4,1A3,3,0,1,0,7,4,3,3,0,0,0,4,1M4,0A4,4,0,1,1,0,4,4,4,0,0,1,4,0Z'
          transform='translate(440 -12806)'
          fill='#fff'
        />
      </g>
    </svg>
  </SvgIcon>
)

export default memo(MenuExperienceCreatorIcon)
