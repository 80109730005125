import { SvgIcon } from '@mui/material'
import { memo } from "preact/compat"

const MenuSettingsIcon = props => (
  <SvgIcon {...props}>
    <svg
      id='Menu_Settings'
      data-name='Menu Settings'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'>
      <path id='Path_920' data-name='Path 920' d='M0,0H24V24H0Z' fill='none' />
      <path
        id='Path_921'
        data-name='Path 921'
        d='M12,2.5a2.19,2.19,0,0,1,2.161,1.7,1.224,1.224,0,0,0,1.827.757A2.21,2.21,0,0,1,17.15,4.62,2.25,2.25,0,0,1,19.1,5.784a2.154,2.154,0,0,1-.053,2.229A1.224,1.224,0,0,0,19.8,9.839a2.224,2.224,0,0,1,0,4.322,1.224,1.224,0,0,0-.757,1.827,2.154,2.154,0,0,1,.053,2.229,2.25,2.25,0,0,1-1.948,1.164,2.211,2.211,0,0,1-1.162-.336,1.224,1.224,0,0,0-1.826.756,2.224,2.224,0,0,1-4.322,0,1.224,1.224,0,0,0-1.827-.757,2.21,2.21,0,0,1-1.163.336A2.25,2.25,0,0,1,4.9,18.216a2.154,2.154,0,0,1,.053-2.229A1.224,1.224,0,0,0,4.2,14.161a2.224,2.224,0,0,1,0-4.322,1.224,1.224,0,0,0,.757-1.827A2.154,2.154,0,0,1,4.9,5.784,2.25,2.25,0,0,1,6.851,4.619a2.21,2.21,0,0,1,1.162.336A1.221,1.221,0,0,0,9.839,4.2,2.19,2.19,0,0,1,12,2.5Zm3.35,3.635a2.224,2.224,0,0,1-2.161-1.7,1.224,1.224,0,0,0-2.378,0A2.221,2.221,0,0,1,7.493,5.809a1.225,1.225,0,0,0-.642-.19,1.266,1.266,0,0,0-1.076.654A1.163,1.163,0,0,0,5.81,7.492a2.224,2.224,0,0,1-1.375,3.319,1.224,1.224,0,0,0,0,2.378,2.224,2.224,0,0,1,1.374,3.318,1.163,1.163,0,0,0-.035,1.219,1.266,1.266,0,0,0,1.076.654,1.224,1.224,0,0,0,.642-.19,2.224,2.224,0,0,1,3.319,1.375,1.224,1.224,0,0,0,2.378,0,2.224,2.224,0,0,1,3.318-1.374,1.225,1.225,0,0,0,.642.19,1.266,1.266,0,0,0,1.076-.654,1.163,1.163,0,0,0-.035-1.219,2.224,2.224,0,0,1,1.375-3.319,1.224,1.224,0,0,0,0-2.378,2.224,2.224,0,0,1-1.374-3.318,1.163,1.163,0,0,0,.035-1.219A1.265,1.265,0,0,0,17.15,5.62a1.225,1.225,0,0,0-.642.19A2.223,2.223,0,0,1,15.35,6.135Z'
        fill='#fff'
      />
      <path
        id='Ellipse_293'
        data-name='Ellipse 293'
        d='M3-.5A3.5,3.5,0,1,1-.5,3,3.5,3.5,0,0,1,3-.5Zm0,6A2.5,2.5,0,1,0,.5,3,2.5,2.5,0,0,0,3,5.5Z'
        transform='translate(9 9)'
        fill='#fff'
      />
    </svg>
  </SvgIcon>
)

export default memo(MenuSettingsIcon)
