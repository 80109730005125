import { Fragment, FunctionalComponent, h } from "preact";
import { WorkspaceOnlyResponse } from "../../models/user-workspace";
import { memo, useEffect, useState } from "preact/compat";
import { MenuItem } from "@mui/material";
import { getWorkspaseAliasById } from "../../api/alias-api";
import Spinner from "../spinner";
import styles from "./styles.module.scss";

interface IProps {
  item: WorkspaceOnlyResponse;
  userId: string;
  token: string;
  handleSelectNewWorkspace: (workspaceId: string) => void;
}

const WorkspaceItem: FunctionalComponent<IProps> = ({
  item,
  userId,
  token,
  handleSelectNewWorkspace,
}) => {

  const [workspaceAlias, setWorkspaceAlias] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true)
    const fetch = async () => {
      const alias = await getWorkspaseAliasById(
        item.workspaceId,
        userId,
        token
      );

      setWorkspaceAlias(alias);
      setIsLoading(false)
    };

    fetch();
  }, [item?.workspaceId]);

  return (
    <Fragment>
      <MenuItem value={item.workspaceId} onClick={() => handleSelectNewWorkspace(item.workspaceId)} style={{color:"#081D34", fontSize:"14px", fontFamily:"Manrope"}}>
        {isLoading ? <Spinner />: workspaceAlias || item.workspaceName}
      </MenuItem>
    </Fragment>
  );
};

export default WorkspaceItem;
