import { getAiHost, getAuthHost } from "../configs/env";
import { IResponse } from "../models/http";
import {
  IWorkspace,
  UpdateWorkspaceIsPayingSettingsRequest,
  UpdateWorkspaceSettingsRequest,
  UpdateWorkspaceMultipleLanguagesSettingsRequest
} from "../models/workspace";

export const creatNewWorkspace = async (
  name: string,
  userId: string,
  userIdToken: string
): Promise<IWorkspace> => {
  const url = `${getAuthHost()}/workspaces`;

  const reqObj = {
    name,
    userId,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userIdToken}`,
    },
    method: "POST",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const workspaceResp: IResponse<IWorkspace> = await res.json();

  if (workspaceResp.error) {
    throw new Error(workspaceResp.error.message);
  } else {
    return workspaceResp.data;
  }
};

export const getWorkspaceById = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<IWorkspace> => {
  const url = `${getAuthHost()}/workspaces/${workspaceId}?userId=${userId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const workspaceResp: IResponse<IWorkspace> = await res.json();

  if (workspaceResp.error) {
    throw new Error(workspaceResp.error.message);
  } else {
    return workspaceResp.data;
  }
};

export const updateWorkspaceSettings = async (
  companyName: string,
  website: string,
  country: string,
  workspaceId: string,
  userId: string,
  token: string
): Promise<IWorkspace> => {
  var url = new URL(
    `${getAuthHost()}/workspaces/${workspaceId}/update-settings`
  );

  const reqObj: UpdateWorkspaceSettingsRequest = {
    companyName,
    website,
    country,
    workspaceId,
    userId,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<IWorkspace> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};

export const updateWorkspaceIsPayingSettings = async (
  isPaying: boolean,
  workspaceId: string,
  userId: string,
  token: string
): Promise<IWorkspace> => {
  var url = new URL(
    `${getAuthHost()}/workspaces/${workspaceId}/update-is-paying-settings`
  );

  const reqObj: UpdateWorkspaceIsPayingSettingsRequest = {
    isPaying,
    workspaceId,
    userId,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<IWorkspace> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};


export const updateWorkspaceMultipleLanguagesSettings = async (
  MultipleLanguages: boolean,
  workspaceId: string,
  userId: string,
  token: string
): Promise<IWorkspace> => {
  var url = new URL(
    `${getAuthHost()}/workspaces/${workspaceId}/update-is-multiple-languages`
  );

  const reqObj: UpdateWorkspaceMultipleLanguagesSettingsRequest = {
    MultipleLanguages,
    workspaceId,
    userId,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<IWorkspace> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};

//check if the current user is the admin of the workspace (works with the token signature)
export const getIsWorkspaceAdmin = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<boolean> => {
  const url = `${getAuthHost()}/workspaces/${workspaceId}/is-admin?userId=${userId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const workspaceResp: IResponse<boolean> = await res.json();

  if (workspaceResp.error) {
    throw new Error(workspaceResp.error.message);
  } else {
    return workspaceResp.data;
  }
};

export const advancedIngestionEnable = async (
  workspaceId: string,
  userId: string,
  userIdToken: string
): Promise<IWorkspace> => {
  const url = `${getAiHost()}/config_manager/workspace/${workspaceId}/advanced_injestion/enable`;

  const reqObj = {
    userId,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userIdToken}`,
    },
    method: "POST",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const workspaceResp: IResponse<IWorkspace> = await res.json();

  if (workspaceResp.error) {
    throw new Error(workspaceResp.error.message);
  } else {
    return workspaceResp.data;
  }
};

export const advancedIngestionDisable = async (
  workspaceId: string,
  userId: string,
  userIdToken: string
): Promise<IWorkspace> => {
  const url = `${getAiHost()}/config_manager/workspace/${workspaceId}/advanced_injestion/disable`;

  const reqObj = {
    userId,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userIdToken}`,
    },
    method: "POST",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const workspaceResp: IResponse<IWorkspace> = await res.json();

  if (workspaceResp.error) {
    throw new Error(workspaceResp.error.message);
  } else {
    return workspaceResp.data;
  }
};

export const getAdvancedIngestionStatus = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<string> => {
  const url = `${getAiHost()}/config_manager/workspace/${workspaceId}/table_transformers/is_enabled?userId=${userId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const response: any = await res.json();

  if (response.error) {
    throw new Error(response.error.message);
  } else {
    return response.message;
  }
};
