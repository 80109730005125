import { Modal } from "@mui/material";
import { Fragment, FunctionalComponent, h } from "preact";

import { useUserAuthContext } from "../../context/UserAuthContext";
import { isMobile } from "react-device-detect";

import styles from "./styles.module.scss";
import CloseIcon from "../../assets/svg/CloseIcon";
import ReactHlsPlayer from "./react-hls-player/dist";

interface IProps {}

const FirstLogin: FunctionalComponent<IProps> = ({}) => {
  const { setIsFirstLogin, isFirstLogin } = useUserAuthContext();

  return (
    <Fragment>
      {isMobile ? (
        <div className={styles.container}>
          <div className={styles.stripe}>
            <img src={"../../assets/KorraAI_Logo.png"} />
            <button
              className={styles.closeBtn}
              onClick={() => setIsFirstLogin(false)}
            >
              <span>Skip</span>
              <CloseIcon white={false} />
            </button>
          </div>
          <ReactHlsPlayer
            src="https://korrastorage.blob.core.windows.net/63905211c8241b0bc61b36fe/64181b2bb2ca59679e2c1dd8/3b75f343-2c7a-4a4e-ae66-87fa84457244/video_parts/video.m3u8"
            autoPlay={true}
            className="videoPlayer"
            controls={true}
            muted={true}
          />
        </div>
      ) : (
        <Modal open={isFirstLogin} onClose={() => setIsFirstLogin(false)}>
          <div className={styles.desktopWrapper}>
            <div className={styles.title}>
              Quick Start
              <button
                className={styles.closeBtn}
                onClick={() => setIsFirstLogin(false)}
              >
                <CloseIcon white={false} />
              </button>
            </div>
            <div className={styles.videoWrapper}>
              <ReactHlsPlayer
                className="videoPlayer"
                src="https://korrastorage.blob.core.windows.net/63905211c8241b0bc61b36fe/64181b2bb2ca59679e2c1dd8/3b75f343-2c7a-4a4e-ae66-87fa84457244/video_parts/video.m3u8"
                autoPlay={true}
                controls={true}
                muted={true}
              />
            </div>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

export default FirstLogin;
