import { SvgIcon } from '@mui/material'
import { memo } from "preact/compat"

const MenuKnowledgeManagerIcon = props => (
  <SvgIcon {...props}>
    <svg
      id='Menu_KM'
      data-name='Menu KM'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'>
      <path id='Path_926' data-name='Path 926' d='M0,0H24V24H0Z' fill='none' />
      <path
        id='Path_927'
        data-name='Path 927'
        d='M19,19.5H5A2.5,2.5,0,0,1,2.5,17V6A2.5,2.5,0,0,1,5,3.5H9a.5.5,0,0,1,.354.146L12.207,6.5H19A2.5,2.5,0,0,1,21.5,9v8A2.5,2.5,0,0,1,19,19.5ZM5,4.5A1.5,1.5,0,0,0,3.5,6V17A1.5,1.5,0,0,0,5,18.5H19A1.5,1.5,0,0,0,20.5,17V9A1.5,1.5,0,0,0,19,7.5H12a.5.5,0,0,1-.354-.146L8.793,4.5Z'
        fill='#fff'
      />
      <path
        id='Line_158'
        data-name='Line 158'
        d='M0,6.5A.5.5,0,0,1-.5,6V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V6A.5.5,0,0,1,0,6.5Z'
        transform='translate(12 10)'
        fill='#fff'
      />
      <path
        id='Line_159'
        data-name='Line 159'
        d='M6,.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H6a.5.5,0,0,1,.5.5A.5.5,0,0,1,6,.5Z'
        transform='translate(9 13)'
        fill='#fff'
      />
    </svg>
  </SvgIcon>
)

export default memo(MenuKnowledgeManagerIcon)
